<template>
    <div>
        <!-- Loading Spinner -->
        <div v-if="isLoading" class="flex items-center justify-center">
            <LoadingSpinner />
        </div>

        <!-- Main Content -->
        <div v-else>
            <div class="pb-5">
                <div class="mb-4 flex items-center justify-between">
                    <!-- Back Button -->
                    <button @click="goBack" class="hover:bg-primary-dark flex items-center rounded-md bg-primary px-4 py-2 text-white transition-colors">
                        <!-- SVG Arrow Icon -->
                        <svg class="mr-2 h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path>
                        </svg>
                        Back
                    </button>

                    <!-- Buttons Group -->
                    <div class="ml-auto grid grid-cols-4 gap-2">
                        <NuxtLink @click="openExpenseModal" class="btn btn-secondary">Add Expense</NuxtLink>
                        <NuxtLink :to="activityUrl" class="btn btn-primary">Add Activity</NuxtLink>
                        <button @click="wonButton" class="btn btn-outline-primary">Won</button>
                        <button @click="openLossModal" class="btn btn-outline-primary">Lost</button>
                    </div>
                </div>
            </div>
            <div class="container mx-auto rounded-lg bg-white p-6 shadow-md">
                <div class="mb-4 flex items-center justify-between">
                    <h2 class="text-2xl font-semibold">{{ title }}</h2>
                    <div class="grid grid-cols-1 gap-2">
                        <div class="mb-5">
                            <ol class="flex font-semibold text-primary dark:text-white-dark">
                                <li
                                    v-for="(stage, index) in projectList"
                                    :key="stage.id"
                                    :class="{
                                        'bg-[#ebedf2]': true,
                                        'dark:bg-[#1b2e4b]': true,
                                        'ltr:rounded-l-md': index === 0,
                                        'rtl:rounded-r-md': index === projectList.length - 1,
                                        'bg-primary text-white': stage.id === activeProjectId,
                                    }"
                                >
                                    <a
                                        href="javascript:;"
                                        :class="{
                                            'relative mx-5 flex h-full items-center p-1.5 before:absolute before:inset-y-0 before:z-[1] before:m-auto before:h-0 before:w-0 before:border-r-0 before:border-b-transparent before:border-l-[#ebedf2] before:border-t-transparent hover:text-primary/70 dark:before:border-l-[#1b2e4b] dark:hover:text-white-dark/70 ltr:pl-3 ltr:pr-2 ltr:before:-right-[15px] rtl:pl-2 rtl:pr-3 rtl:before:-left-[15px] rtl:before:rotate-180':
                                                'relative flex h-full items-center p-1.5 px-3 before:absolute before:inset-y-0 before:z-[1] before:m-auto before:h-0 before:w-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-b-transparent before:border-l-[#ebedf2] before:border-t-transparent hover:text-primary/70 dark:before:border-l-[#1b2e4b] dark:hover:text-white-dark/70 ltr:pl-6 ltr:before:-right-[15px] rtl:pr-6 rtl:before:-left-[15px] rtl:before:rotate-180',
                                        }"
                                    >
                                        {{ stage.display_name }}
                                    </a>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>

                <!-- Opportunity Details -->
                <div class="mb-4">
                    <div class="flex items-center justify-between">
                        <span class="text-lg">Expected Revenue</span>
                        <span class="text-lg font-semibold">{{ formattedRevenue }}</span>
                    </div>
                    <div class="flex items-center justify-between">
                        <span class="text-lg">Probability</span>
                        <span class="text-lg font-semibold">{{ probability }}%</span>
                    </div>
                </div>
                <div class="mb-4">
                    <div>
                        <span class="block text-sm">Customer</span>
                        <span class="text-lg">{{ title }}</span>
                    </div>
                    <div>
                        <span class="block text-sm">Email</span>
                        <span class="text-lg">{{ email }}</span>
                    </div>
                    <div>
                        <span class="block text-sm">Phone</span>
                        <span class="text-lg">{{ phone }}</span>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="flex items-center justify-between">
                        <span class="text-lg">Expected Closing</span>
                        <span class="text-lg font-semibold">{{ formattedDate }}</span>
                    </div>
                    <div class="flex items-center justify-between">
                        <span class="text-lg">Tags</span>
                        <span class="text-lg font-semibold">{{ tags }}</span>
                    </div>
                </div>

                <div class="grid grid-cols-1 gap-5 lg:grid-cols-2">
                    <div class="panel">
                        <div class="mt-8">
                            <h3 class="mb-4 text-xl font-semibold">Activities</h3>
                            <div v-if="activities.length === 0" class="text-gray-500">No activities found.</div>
                            <ul v-else>
                                <li v-for="activity in activities" :key="activity.id" class="mb-4 rounded-md bg-gray-100 p-4 shadow-sm">
                                    <h4 class="text-lg font-semibold">{{ activity.name }}</h4>
                                    <p class="text-sm text-gray-700" v-html="activity.description"></p>
                                    <p class="text-sm text-gray-500">Start: {{ formatDate(activity.start) }}</p>
                                    <p class="text-sm text-gray-500">End: {{ formatDate(activity.stop) }}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="panel">
                        <h3 class="mb-4 text-xl font-semibold">Expenses</h3>
                        <div v-if="expenses.length === 0" class="text-gray-500">No expenses found.</div>
                        <div v-else class="grid grid-cols-1 gap-2">
                            <div v-for="expense in expenses" :key="expense.id" class="mb-4 rounded-md bg-gray-100 p-4 shadow-sm">
                                <h4 class="text-lg font-semibold">{{ expense.name }}</h4>
                                <p class="text-sm text-gray-700">{{ expense.description }}</p>
                                <p class="text-sm text-gray-500">Date: {{ formatDate(expense.date_transaction) }}</p>
                                <!-- <p class="text-sm text-gray-500">Amount: Rp{{ formatNumber(expense.value_expense) }}</p> -->
                            </div>
                        </div>
                    </div>
                </div>

                <!-- List of Activities -->
            </div>
        </div>

        <!-- Loss Modal -->
        <transition name="fade">
            <div v-if="isLossModalOpen" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                <div class="w-1/3 rounded-lg bg-white shadow-lg">
                    <div class="border-b p-4">
                        <h3 class="text-xl font-semibold">Mark Lost</h3>
                    </div>
                    <div class="p-4">
                        <!-- Lost Reason Input -->
                        <div class="mb-4">
                            <label class="block text-sm font-medium text-gray-700">Lost Reason</label>
                            <input
                                v-model="lostReason"
                                type="text"
                                class="mt-1form-input block w-full rounded-md border border-gray-300 shadow-sm"
                                placeholder="Enter reason"
                            />
                        </div>
                    </div>
                    <div class="flex justify-end border-t p-4">
                        <button @click="markAsLost" class="btn btn-primary mr-2">Mark as Lost</button>
                        <button @click="closeLossModal" class="btn btn-secondary">Cancel</button>
                    </div>
                </div>
            </div>
        </transition>

        <!-- Expense Modal -->
        <transition name="fade">
            <div v-if="isExpenseModalOpen" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                <div class="w-1/3 rounded-lg bg-white shadow-lg">
                    <div class="border-b p-4">
                        <h3 class="text-xl font-semibold">Add Expense</h3>
                    </div>
                    <div class="p-4">
                        <div class="mb-4">
                            <label class="block text-sm font-medium text-gray-700">Name</label>
                            <input
                                v-model="expenseName"
                                type="text"
                                class="form-input mt-1 block w-full rounded-md border border-gray-300 shadow-sm"
                                placeholder="Expense Name"
                            />
                        </div>
                        <div class="mb-4">
                            <label class="block text-sm font-medium text-gray-700">Description</label>
                            <textarea
                                v-model="expenseDescription"
                                class="form-input mt-1 block w-full rounded-md border border-gray-300 shadow-sm"
                                placeholder="Expense Description"
                            ></textarea>
                        </div>
                        <div class="mb-4">
                            <label class="block text-sm font-medium text-gray-700">Date</label>
                            <input v-model="expenseDate" type="date" class="form-input mt-1 block w-full rounded-md border border-gray-300 shadow-sm" />
                        </div>
                        <div class="mb-4">
                            <label class="block text-sm font-medium text-gray-700">Amount</label>
                            <input
                                v-model="expenseAmount"
                                type="number"
                                class="form-input mt-1 block w-full rounded-md border border-gray-300 shadow-sm"
                                placeholder="Expense Amount"
                            />
                        </div>
                    </div>
                    <div class="flex justify-end border-t p-4">
                        <button @click="addExpense" class="btn btn-primary mr-2">Add Expense</button>
                        <button @click="closeExpenseModal" class="btn btn-secondary">Cancel</button>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
    import { defineComponent, ref, onMounted, computed } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { getCRMStageList, createLeadsExpense, listCalendarActivities, updateCrmLead, updateCrmLeadLost, getListCrmExpense } from '~/utils/apiService';
    import { useAppStore } from '@/stores/index';
    import { useAuthStore } from '@/stores/authStore';
    import { useLeadsStore } from '@/stores/leadsStore';
    import LoadingSpinner from '~/components/LoadingSpinner.vue';

    export default defineComponent({
        name: 'OpportunityPage',
        components: {
            LoadingSpinner,
        },
        setup() {
            const store = useAppStore();
            const leadsStore = useLeadsStore();
            const authStore = useAuthStore();
            const route = useRoute();
            const router = useRouter();

            const title = ref('');
            const id = ref('');
            const email = ref('');
            const phone = ref('');
            const probability = ref<number | null>(null);
            const revenue = ref<number | null>(null);
            const formattedDate = ref('');
            const tags = ref('');
            const projectList = ref([]);
            const expenses = ref([]);
            const activeProjectId = ref<number | null>(null);
            const activities = ref<any[]>([]);
            const isLoading = ref(true); // Loading state
            // Modal state
            const isLossModalOpen = ref(false);
            const selectedLostReason = ref('');
            const lostReason = ref('');
            const isExpenseModalOpen = ref(false); // Expense Modal State
            const expenseName = ref('');
            const expenseDescription = ref('');
            const expenseDate = ref('');
            const expenseAmount = ref<number | null>(null);

            const closingNote = ref('');
            definePageMeta({
                middleware: 'auth',
            });
            const openLossModal = () => {
                isLossModalOpen.value = true;
            };

            const closeLossModal = () => {
                isLossModalOpen.value = false;
                lostReason.value = '';
                closingNote.value = '';
            };

            const markAsLost = async () => {
                try {
                    if (lostReason.value == '') {
                        showMessage('Please select a reason for the loss.', 'danger');
                        return;
                    }

                    const dataCrm = {
                        company_id: parseInt(authStore.companyId),
                        lead_id: parseInt(id.value),
                        partner_id: parseInt(authStore.partnerId),
                        lost_reason: lostReason.value,
                        offset: 0,
                        limit: 0,
                    };
                    await updateCrmLeadLost(dataCrm);
                    showMessage('Opportunity marked as lost.', 'success');
                    closeLossModal();
                    router.back();
                } catch (error) {
                    console.error('Failed to mark opportunity as lost', error);
                }
            };

            const formatRevenue = (value: number | null) => {
                return value ? `Rp${value.toLocaleString()}.00` : 'N/A';
            };

            const formatDate = (dateStr: string) => {
                const date = new Date(dateStr);
                return date.toLocaleDateString('id-ID') + ' ' + date.toLocaleTimeString('id-ID');
            };

            const wonButton = async () => {
                try {
                    const dataCrm = {
                        company_id: parseInt(authStore.companyId),
                        lead_id: parseInt(id.value),
                        partner_id: parseInt(authStore.partnerId),
                        edit: {
                            stage_id: 4,
                        },
                    };
                    await updateCrmLead(dataCrm);
                    // fetchCRMStageList()
                    showMessage('Task project assignment has been updated.', 'success');
                } catch (error) {
                    console.error('Failed to update task project assignment', error);
                }
            };
            const lossButton = () => {};
            const goBack = () => {
                router.back();
            };

            const fetchCRMStageList = async () => {
                try {
                    const params = {
                        name: '',
                        limit: 0,
                        offset: 0,
                        stage_id: 0,
                    };
                    const response = await getCRMStageList(params);
                    projectList.value = response.data;
                } catch (error) {
                    console.error('Failed to fetch CRM stages', error);
                }
            };

            const fetchCalendarActivities = async () => {
                try {
                    const params = {
                        company_id: parseInt(authStore.companyId),
                        description: '',
                        lead_id: parseInt(id.value),
                        limit: 0,
                        name: '',
                        offset: 0,
                        partner_id: parseInt(authStore.partnerId),
                        start_date: '',
                        stop_date: '',
                    };
                    const response = await listCalendarActivities(params);
                    activities.value = response.data;
                } catch (error) {
                    console.error('Failed to fetch calendar activities', error);
                }
            };
            const fetchCrmListExpense = async () => {
                try {
                    const params = {
                        company_id: parseInt(authStore.companyId),
                        description: '',
                        expenses_id: 0,
                        limit: 0,
                        name: '',
                        offset: 0,
                        partner_id: parseInt(authStore.partnerId),
                        user_id: parseInt(authStore.userId),
                    };
                    const response = await getListCrmExpense(params);
                    expenses.value = response.data;
                } catch (error) {
                    console.error('Failed to fetch calendar activities', error);
                }
            };

            onMounted(async () => {
                try {
                    await fetchCRMStageList();
                    await fetchCalendarActivities();
                    await fetchCrmListExpense();
                } finally {
                    isLoading.value = false; // Set loading to false after fetching
                }

                const {
                    title: qTitle,
                    email: qEmail,
                    phone: qPhone,
                    probability: qProbability,
                    revenue: qRevenue,
                    date: qDate,
                    tags: qTags,
                    id: qID,
                    projectId,
                } = route.query;

                title.value = (qTitle as string) || '';
                id.value = (qID as string) || '';
                email.value = (qEmail as string) || '';
                phone.value = (qPhone as string) || '';
                probability.value = qProbability ? Number(qProbability) : null;
                revenue.value = qRevenue ? Number(qRevenue) : null;
                formattedDate.value = (qDate as string) || '';
                tags.value = (qTags as string) || '';
                activeProjectId.value = projectId ? Number(projectId) : null;
            });

            // Construct URL with current query parameters
            const activityUrl = computed(() => {
                const queryParams = new URLSearchParams(route.query as any).toString();
                return `/admin/apps/crm/activity?${queryParams}`;
            });

            const openExpenseModal = () => {
                isExpenseModalOpen.value = true;
            };

            const closeExpenseModal = () => {
                isExpenseModalOpen.value = false;
                expenseName.value = '';
                expenseDescription.value = '';
                expenseDate.value = '';
                expenseAmount.value = null;
            };

            const addExpense = async () => {
                // Handle the logic to add expense here
                console.log('Expense Added:', {
                    name: expenseName.value,
                    description: expenseDescription.value,
                    date: expenseDate.value,
                    amount: expenseAmount.value,
                });
                try {
                    const dataCrm = {
                        company_id: parseInt(authStore.companyId),
                        date_transaction: expenseDate.value,
                        description: expenseDescription.value,
                        name: expenseName.value,
                        lead_id: parseInt(id.value),
                        limit: 0,
                        offset: 0,
                        partner_id: parseInt(authStore.partnerId),
                        user_id: parseInt(authStore.userId),
                        value_expense: parseInt(expenseAmount.value),
                    };
                    await createLeadsExpense(dataCrm);
                    // fetchCRMStageList()
                    showMessage('Task project assignment has been updated.', 'success');
                } catch (error) {
                    console.error('Failed to update task project assignment', error);
                }

                // createLeadsExpense

                // After adding the expense, close the modal
                closeExpenseModal();
            };

            return {
                id,
                title,
                email,
                phone,
                probability,
                formattedRevenue: computed(() => formatRevenue(revenue.value)),
                formattedDate,
                tags,
                projectList,
                goBack,
                activeProjectId,
                activityUrl,
                activities,
                formatDate,
                isLoading,
                wonButton,
                openLossModal,
                closeLossModal,
                markAsLost,
                isLossModalOpen,
                lostReason,
                closingNote,
                openExpenseModal,
                closeExpenseModal,
                isExpenseModalOpen,
                expenseName,
                expenseDescription,
                expenseDate,
                expenseAmount,
                addExpense,
                expenses,
            };
        },
    });
</script>

<style scoped></style>
