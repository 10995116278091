import { default as indexk1G1sQDyHOMeta } from "/app/pages/admin/apps/bussiness/index.vue?macro=true";
import { default as calendar1RLu1JEQl9Meta } from "/app/pages/admin/apps/calendar.vue?macro=true";
import { default as chatyIMVLlPeCCMeta } from "/app/pages/admin/apps/chat.vue?macro=true";
import { default as index8AVoOuhDjPMeta } from "/app/pages/admin/apps/contacts/index.vue?macro=true";
import { default as sellergjRRw6MwhgMeta } from "/app/pages/admin/apps/contacts/seller.vue?macro=true";
import { default as addMaj5i5hV6tMeta } from "/app/pages/admin/apps/contract/add.vue?macro=true";
import { default as indexnLOmjh5WYRMeta } from "/app/pages/admin/apps/contract/index.vue?macro=true";
import { default as updaterO947Lak8AMeta } from "/app/pages/admin/apps/contract/update.vue?macro=true";
import { default as activitypdMT2Vs7jGMeta } from "/app/pages/admin/apps/crm/activity.vue?macro=true";
import { default as detail_32copyMU3nY1k94yMeta } from "/app/pages/admin/apps/crm/detail copy.vue?macro=true";
import { default as detail_leadss07GFgjid5Meta } from "/app/pages/admin/apps/crm/detail_leads.vue?macro=true";
import { default as detail1r2k51BVUyMeta } from "/app/pages/admin/apps/crm/detail.vue?macro=true";
import { default as index_32copymiMfPSQyk2Meta } from "/app/pages/admin/apps/crm/index copy.vue?macro=true";
import { default as indexVhE32NzxbYMeta } from "/app/pages/admin/apps/crm/index.vue?macro=true";
import { default as addteRheAI1unMeta } from "/app/pages/admin/apps/developer/add.vue?macro=true";
import { default as indexnqfgAJ6pevMeta } from "/app/pages/admin/apps/developer/index.vue?macro=true";
import { default as index5i214nhpmiMeta } from "/app/pages/admin/apps/financials/index.vue?macro=true";
import { default as prepaidbcqT8KzYcLMeta } from "/app/pages/admin/apps/financials/prepaid.vue?macro=true";
import { default as addVcbfOQsKgtMeta } from "/app/pages/admin/apps/invoice/add.vue?macro=true";
import { default as edit8hirfV1m2sMeta } from "/app/pages/admin/apps/invoice/edit.vue?macro=true";
import { default as listXRhG5XeWgsMeta } from "/app/pages/admin/apps/invoice/list.vue?macro=true";
import { default as previewfd9uyFoFYLMeta } from "/app/pages/admin/apps/invoice/preview.vue?macro=true";
import { default as addJCX2YAgyoEMeta } from "/app/pages/admin/apps/listing/add.vue?macro=true";
import { default as detailt8VV44BPjQMeta } from "/app/pages/admin/apps/listing/detail.vue?macro=true";
import { default as edit6PXqgaDSs0Meta } from "/app/pages/admin/apps/listing/edit.vue?macro=true";
import { default as indexrHPkv19k52Meta } from "/app/pages/admin/apps/listing/index.vue?macro=true";
import { default as mailboxCbBiG8SoKdMeta } from "/app/pages/admin/apps/mailbox.vue?macro=true";
import { default as detailBvOOrebZhtMeta } from "/app/pages/admin/apps/marketing/ads/detail.vue?macro=true";
import { default as indexgnoNqUUnsjMeta } from "/app/pages/admin/apps/marketing/ads/index.vue?macro=true";
import { default as addaKaSRI6YQIMeta } from "/app/pages/admin/apps/marketing/email/add.vue?macro=true";
import { default as indexi2sI9YexixMeta } from "/app/pages/admin/apps/marketing/email/index.vue?macro=true";
import { default as updatedFQ06ISGy0Meta } from "/app/pages/admin/apps/marketing/email/update.vue?macro=true";
import { default as callback4EY8sQQWbAMeta } from "/app/pages/admin/apps/marketing/social_facebook/callback.vue?macro=true";
import { default as callbacktRLWoyTaLvMeta } from "/app/pages/admin/apps/marketing/social_instagram/callback.vue?macro=true";
import { default as notesuJce14B4jdMeta } from "/app/pages/admin/apps/notes.vue?macro=true";
import { default as opportunitiesgzfaHtfqiOMeta } from "/app/pages/admin/apps/opportunities.vue?macro=true";
import { default as scrumboardlI93o3ReP4Meta } from "/app/pages/admin/apps/scrumboard.vue?macro=true";
import { default as addL4XPNybJUfMeta } from "/app/pages/admin/apps/smartaction/add.vue?macro=true";
import { default as indexPtrZjsiT1OMeta } from "/app/pages/admin/apps/smartaction/index.vue?macro=true";
import { default as smartactionbuGvn7i4CdMqMeta } from "/app/pages/admin/apps/smartactionbu.vue?macro=true";
import { default as detail_todolist2dW5wM4oZ2Meta } from "/app/pages/admin/apps/todolist/detail_todolist.vue?macro=true";
import { default as index_32copy_322myaTVrsfpEMeta } from "/app/pages/admin/apps/todolist/index copy 2.vue?macro=true";
import { default as index_32copyGz4ZiVtExEMeta } from "/app/pages/admin/apps/todolist/index copy.vue?macro=true";
import { default as index22e7O8fVODMeta } from "/app/pages/admin/apps/todolist/index.vue?macro=true";
import { default as user_45account_45settingsp7kgT7Fi8sMeta } from "/app/pages/admin/apps/user-account-settings.vue?macro=true";
import { default as detail7jTS4KgBodMeta } from "/app/pages/admin/apps/user/detail.vue?macro=true";
import { default as indexbffr1T5plMMeta } from "/app/pages/admin/apps/user/index.vue?macro=true";
import { default as addRFyn3bjEXAMeta } from "/app/pages/admin/apps/website/add.vue?macro=true";
import { default as detailJxVDsCXtFiMeta } from "/app/pages/admin/apps/website/detail.vue?macro=true";
import { default as domainU1EpGLef56Meta } from "/app/pages/admin/apps/website/domain.vue?macro=true";
import { default as indexQuQgh0JEixMeta } from "/app/pages/admin/apps/website/index.vue?macro=true";
import { default as indexi8nCATZISJMeta } from "/app/pages/admin/index.vue?macro=true";
import { default as invoicef6NPNgRtFpMeta } from "/app/pages/admin/users/invoice.vue?macro=true";
import { default as profile0GQR50wn91Meta } from "/app/pages/admin/users/profile.vue?macro=true";
import { default as profile2icUVa45uJFMeta } from "/app/pages/admin/users/profile2.vue?macro=true";
import { default as user_45account_45settingsF0Fn7eYxJgMeta } from "/app/pages/admin/users/user-account-settings.vue?macro=true";
import { default as analyticsawZ4iWXl3HMeta } from "/app/pages/analytics.vue?macro=true";
import { default as boxed_45change_45resetrxOrXTK4kZMeta } from "/app/pages/auth/boxed-change-reset.vue?macro=true";
import { default as boxed_45lockscreen9PuutlCpOVMeta } from "/app/pages/auth/boxed-lockscreen.vue?macro=true";
import { default as boxed_45password_45reset47WA1dDqs6Meta } from "/app/pages/auth/boxed-password-reset.vue?macro=true";
import { default as boxed_45signinPQdz4KevkGMeta } from "/app/pages/auth/boxed-signin.vue?macro=true";
import { default as boxed_45signup6O9TBPg4tGMeta } from "/app/pages/auth/boxed-signup.vue?macro=true";
import { default as cover_45lockscreenYk2MgkwpFmMeta } from "/app/pages/auth/cover-lockscreen.vue?macro=true";
import { default as cover_45loginuZyWJQcrBoMeta } from "/app/pages/auth/cover-login.vue?macro=true";
import { default as cover_45password_45reset0Dp209zw2BMeta } from "/app/pages/auth/cover-password-reset.vue?macro=true";
import { default as cover_45registervmnxlWtp1cMeta } from "/app/pages/auth/cover-register.vue?macro=true";
import { default as try_45demouZyExdwi4bMeta } from "/app/pages/auth/try-demo.vue?macro=true";
import { default as detailtjvjrgmvoKMeta } from "/app/pages/b2c/detail.vue?macro=true";
import { default as indexZylsoS0OD7Meta } from "/app/pages/b2c/index.vue?macro=true";
import { default as marketingnqQ7kgu8hvMeta } from "/app/pages/b2c/marketing.vue?macro=true";
import { default as propertyCcX57MkNdmMeta } from "/app/pages/b2c/property.vue?macro=true";
import { default as chartsmXog1AvpIqMeta } from "/app/pages/charts.vue?macro=true";
import { default as accordionsUwvwTzg8igMeta } from "/app/pages/components/accordions.vue?macro=true";
import { default as cardsPjbJoa7ViHMeta } from "/app/pages/components/cards.vue?macro=true";
import { default as carouselaGFIr3YbFiMeta } from "/app/pages/components/carousel.vue?macro=true";
import { default as countdownlM1qZBRClqMeta } from "/app/pages/components/countdown.vue?macro=true";
import { default as counterhV3CROPmoPMeta } from "/app/pages/components/counter.vue?macro=true";
import { default as lightboxzYXPqqPhLRMeta } from "/app/pages/components/lightbox.vue?macro=true";
import { default as list_45groupzPlFcWhgP8Meta } from "/app/pages/components/list-group.vue?macro=true";
import { default as media_45objectLlNVOsVPpEMeta } from "/app/pages/components/media-object.vue?macro=true";
import { default as modalsvcZKjEcmSIMeta } from "/app/pages/components/modals.vue?macro=true";
import { default as notificationsmlJEGUOXcIMeta } from "/app/pages/components/notifications.vue?macro=true";
import { default as pricing_45tablew9AhkmytE4Meta } from "/app/pages/components/pricing-table.vue?macro=true";
import { default as sweetalert0d0XYlgpbCMeta } from "/app/pages/components/sweetalert.vue?macro=true";
import { default as tabsxnPxR4FkY3Meta } from "/app/pages/components/tabs.vue?macro=true";
import { default as timeline6adsDXNVPUMeta } from "/app/pages/components/timeline.vue?macro=true";
import { default as crypto3wfXLz1OUkMeta } from "/app/pages/crypto.vue?macro=true";
import { default as advancedn4tKgGV5IhMeta } from "/app/pages/datatables/advanced.vue?macro=true";
import { default as alt_45paginationChZ9tlCMFJMeta } from "/app/pages/datatables/alt-pagination.vue?macro=true";
import { default as basicAcR9PDUtBPMeta } from "/app/pages/datatables/basic.vue?macro=true";
import { default as checkboxIMYFCQ1X0QMeta } from "/app/pages/datatables/checkbox.vue?macro=true";
import { default as clone_45headerOoQyiYCewtMeta } from "/app/pages/datatables/clone-header.vue?macro=true";
import { default as column_45chooserM4a7xaUmrFMeta } from "/app/pages/datatables/column-chooser.vue?macro=true";
import { default as columns_45filter3pnQpBq1PNMeta } from "/app/pages/datatables/columns-filter.vue?macro=true";
import { default as exporteznYh68XblMeta } from "/app/pages/datatables/export.vue?macro=true";
import { default as multi_45column47lVAQAb3oMeta } from "/app/pages/datatables/multi-column.vue?macro=true";
import { default as multiple_45tablesqL0WjDoP4bMeta } from "/app/pages/datatables/multiple-tables.vue?macro=true";
import { default as order_45sortingEPRDOqKsgwMeta } from "/app/pages/datatables/order-sorting.vue?macro=true";
import { default as range_45searchxRIL6qkmVBMeta } from "/app/pages/datatables/range-search.vue?macro=true";
import { default as skinfZD3H3UBGJMeta } from "/app/pages/datatables/skin.vue?macro=true";
import { default as sticky_45headerNx7TRt8f24Meta } from "/app/pages/datatables/sticky-header.vue?macro=true";
import { default as dragndropQCs8cVnwWuMeta } from "/app/pages/dragndrop.vue?macro=true";
import { default as editor_32copyOB7YW3itUYMeta } from "/app/pages/editor copy.vue?macro=true";
import { default as editor_45testafqyl9C61VMeta } from "/app/pages/editor-test.vue?macro=true";
import { default as editorkkwNaNZuQBMeta } from "/app/pages/editor.vue?macro=true";
import { default as alerts7wwYLn17IUMeta } from "/app/pages/elements/alerts.vue?macro=true";
import { default as avatarA2Pd91LZRYMeta } from "/app/pages/elements/avatar.vue?macro=true";
import { default as badgesLzwDfXJnRzMeta } from "/app/pages/elements/badges.vue?macro=true";
import { default as breadcrumbs0MWvM7qnSYMeta } from "/app/pages/elements/breadcrumbs.vue?macro=true";
import { default as buttons_45groupIyDmfkCUs8Meta } from "/app/pages/elements/buttons-group.vue?macro=true";
import { default as buttonsKbjhiBShY1Meta } from "/app/pages/elements/buttons.vue?macro=true";
import { default as color_45libraryxNIfy6dCEcMeta } from "/app/pages/elements/color-library.vue?macro=true";
import { default as dropdownfgz6OL21RPMeta } from "/app/pages/elements/dropdown.vue?macro=true";
import { default as infobox6LfI5v5AjpMeta } from "/app/pages/elements/infobox.vue?macro=true";
import { default as jumbotronbQAftCp2hwMeta } from "/app/pages/elements/jumbotron.vue?macro=true";
import { default as loaderXrkieGQGJBMeta } from "/app/pages/elements/loader.vue?macro=true";
import { default as paginationXlxxYI4iKNMeta } from "/app/pages/elements/pagination.vue?macro=true";
import { default as popoversgWqJUdpnWoMeta } from "/app/pages/elements/popovers.vue?macro=true";
import { default as progress_45barAR6RX16OgyMeta } from "/app/pages/elements/progress-bar.vue?macro=true";
import { default as searchXT3z5qS2y1Meta } from "/app/pages/elements/search.vue?macro=true";
import { default as tooltipsfDf31TBwa5Meta } from "/app/pages/elements/tooltips.vue?macro=true";
import { default as treeviewRw0hin8tbUMeta } from "/app/pages/elements/treeview.vue?macro=true";
import { default as typographyeHAr3Wa5DEMeta } from "/app/pages/elements/typography.vue?macro=true";
import { default as financeMjIGPbjS9hMeta } from "/app/pages/finance.vue?macro=true";
import { default as font_45icons30g3Sron1XMeta } from "/app/pages/font-icons.vue?macro=true";
import { default as basic4d0ZhFQRAaMeta } from "/app/pages/forms/basic.vue?macro=true";
import { default as checkbox_45radiog0clgjf09hMeta } from "/app/pages/forms/checkbox-radio.vue?macro=true";
import { default as clipboardT5kxFLN3FCMeta } from "/app/pages/forms/clipboard.vue?macro=true";
import { default as date_45pickerxBpRZUt9KbMeta } from "/app/pages/forms/date-picker.vue?macro=true";
import { default as file_45uploadqdBcW6XYnhMeta } from "/app/pages/forms/file-upload.vue?macro=true";
import { default as input_45groupS5PaG385fyMeta } from "/app/pages/forms/input-group.vue?macro=true";
import { default as input_45maskJ05OMPC6ZtMeta } from "/app/pages/forms/input-mask.vue?macro=true";
import { default as layoutshU8mEbz15ZMeta } from "/app/pages/forms/layouts.vue?macro=true";
import { default as markdown_45editorX8EdcpWp7yMeta } from "/app/pages/forms/markdown-editor.vue?macro=true";
import { default as quill_45editorMEMRSEcMdTMeta } from "/app/pages/forms/quill-editor.vue?macro=true";
import { default as select2Kddnwvxd1NMeta } from "/app/pages/forms/select2.vue?macro=true";
import { default as switchesQX9jLi1wm0Meta } from "/app/pages/forms/switches.vue?macro=true";
import { default as touchspin20lQo5txyJMeta } from "/app/pages/forms/touchspin.vue?macro=true";
import { default as validationoK9hoJ34BkMeta } from "/app/pages/forms/validation.vue?macro=true";
import { default as wizardskeh2RYDhnpMeta } from "/app/pages/forms/wizards.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as invoiceFIX0IQeAuyMeta } from "/app/pages/invoice.vue?macro=true";
import { default as orderBjfxujOe1OMeta } from "/app/pages/order.vue?macro=true";
import { default as coming_45soon_45boxedIiRVY5RmnYMeta } from "/app/pages/pages/coming-soon-boxed.vue?macro=true";
import { default as coming_45soon_45cover9En00x5dcNMeta } from "/app/pages/pages/coming-soon-cover.vue?macro=true";
import { default as contact_45us_45boxedCNp7hQWXH5Meta } from "/app/pages/pages/contact-us-boxed.vue?macro=true";
import { default as contact_45us_45coverDqlH83GANbMeta } from "/app/pages/pages/contact-us-cover.vue?macro=true";
import { default as error404hTN0doY6zqMeta } from "/app/pages/pages/error404.vue?macro=true";
import { default as error500OQ7pwxlt92Meta } from "/app/pages/pages/error500.vue?macro=true";
import { default as error503mOESQwwiUHMeta } from "/app/pages/pages/error503.vue?macro=true";
import { default as faq9zkrynBMU3Meta } from "/app/pages/pages/faq.vue?macro=true";
import { default as knowledge_45baseZEbA2pHmpEMeta } from "/app/pages/pages/knowledge-base.vue?macro=true";
import { default as maintenenceZfksFlNFGfMeta } from "/app/pages/pages/maintenence.vue?macro=true";
import { default as profileupBlgdy3vLMeta } from "/app/pages/profile.vue?macro=true";
import { default as success0bZk2aWKHZMeta } from "/app/pages/success.vue?macro=true";
import { default as tablesObwGmyK6sPMeta } from "/app/pages/tables.vue?macro=true";
import { default as trial_45modevh6rBg3TbfMeta } from "/app/pages/trial-mode.vue?macro=true";
import { default as widgets3jvG0ysNM4Meta } from "/app/pages/widgets.vue?macro=true";
export default [
  {
    name: "admin-apps-bussiness",
    path: "/admin/apps/bussiness",
    meta: indexk1G1sQDyHOMeta || {},
    component: () => import("/app/pages/admin/apps/bussiness/index.vue")
  },
  {
    name: "admin-apps-calendar",
    path: "/admin/apps/calendar",
    component: () => import("/app/pages/admin/apps/calendar.vue")
  },
  {
    name: "admin-apps-chat",
    path: "/admin/apps/chat",
    component: () => import("/app/pages/admin/apps/chat.vue")
  },
  {
    name: "admin-apps-contacts",
    path: "/admin/apps/contacts",
    meta: index8AVoOuhDjPMeta || {},
    component: () => import("/app/pages/admin/apps/contacts/index.vue")
  },
  {
    name: "admin-apps-contacts-seller",
    path: "/admin/apps/contacts/seller",
    meta: sellergjRRw6MwhgMeta || {},
    component: () => import("/app/pages/admin/apps/contacts/seller.vue")
  },
  {
    name: "admin-apps-contract-add",
    path: "/admin/apps/contract/add",
    component: () => import("/app/pages/admin/apps/contract/add.vue")
  },
  {
    name: "admin-apps-contract",
    path: "/admin/apps/contract",
    component: () => import("/app/pages/admin/apps/contract/index.vue")
  },
  {
    name: "admin-apps-contract-update",
    path: "/admin/apps/contract/update",
    component: () => import("/app/pages/admin/apps/contract/update.vue")
  },
  {
    name: "admin-apps-crm-activity",
    path: "/admin/apps/crm/activity",
    meta: activitypdMT2Vs7jGMeta || {},
    component: () => import("/app/pages/admin/apps/crm/activity.vue")
  },
  {
    name: "admin-apps-crm-detail copy",
    path: "/admin/apps/crm/detail%20copy",
    meta: detail_32copyMU3nY1k94yMeta || {},
    component: () => import("/app/pages/admin/apps/crm/detail copy.vue")
  },
  {
    name: "admin-apps-crm-detail_leads",
    path: "/admin/apps/crm/detail_leads",
    meta: detail_leadss07GFgjid5Meta || {},
    component: () => import("/app/pages/admin/apps/crm/detail_leads.vue")
  },
  {
    name: "admin-apps-crm-detail",
    path: "/admin/apps/crm/detail",
    meta: detail1r2k51BVUyMeta || {},
    component: () => import("/app/pages/admin/apps/crm/detail.vue")
  },
  {
    name: "admin-apps-crm-index copy",
    path: "/admin/apps/crm/index%20copy",
    meta: index_32copymiMfPSQyk2Meta || {},
    component: () => import("/app/pages/admin/apps/crm/index copy.vue")
  },
  {
    name: "admin-apps-crm",
    path: "/admin/apps/crm",
    component: () => import("/app/pages/admin/apps/crm/index.vue")
  },
  {
    name: "admin-apps-developer-add",
    path: "/admin/apps/developer/add",
    meta: addteRheAI1unMeta || {},
    component: () => import("/app/pages/admin/apps/developer/add.vue")
  },
  {
    name: "admin-apps-developer",
    path: "/admin/apps/developer",
    meta: indexnqfgAJ6pevMeta || {},
    component: () => import("/app/pages/admin/apps/developer/index.vue")
  },
  {
    name: "admin-apps-financials",
    path: "/admin/apps/financials",
    meta: index5i214nhpmiMeta || {},
    component: () => import("/app/pages/admin/apps/financials/index.vue")
  },
  {
    name: "admin-apps-financials-prepaid",
    path: "/admin/apps/financials/prepaid",
    meta: prepaidbcqT8KzYcLMeta || {},
    component: () => import("/app/pages/admin/apps/financials/prepaid.vue")
  },
  {
    name: "admin-apps-invoice-add",
    path: "/admin/apps/invoice/add",
    meta: addVcbfOQsKgtMeta || {},
    component: () => import("/app/pages/admin/apps/invoice/add.vue")
  },
  {
    name: "admin-apps-invoice-edit",
    path: "/admin/apps/invoice/edit",
    meta: edit8hirfV1m2sMeta || {},
    component: () => import("/app/pages/admin/apps/invoice/edit.vue")
  },
  {
    name: "admin-apps-invoice-list",
    path: "/admin/apps/invoice/list",
    meta: listXRhG5XeWgsMeta || {},
    component: () => import("/app/pages/admin/apps/invoice/list.vue")
  },
  {
    name: "admin-apps-invoice-preview",
    path: "/admin/apps/invoice/preview",
    component: () => import("/app/pages/admin/apps/invoice/preview.vue")
  },
  {
    name: "admin-apps-listing-add",
    path: "/admin/apps/listing/add",
    meta: addJCX2YAgyoEMeta || {},
    component: () => import("/app/pages/admin/apps/listing/add.vue")
  },
  {
    name: "admin-apps-listing-detail",
    path: "/admin/apps/listing/detail",
    component: () => import("/app/pages/admin/apps/listing/detail.vue")
  },
  {
    name: "admin-apps-listing-edit",
    path: "/admin/apps/listing/edit",
    meta: edit6PXqgaDSs0Meta || {},
    component: () => import("/app/pages/admin/apps/listing/edit.vue")
  },
  {
    name: "admin-apps-listing",
    path: "/admin/apps/listing",
    meta: indexrHPkv19k52Meta || {},
    component: () => import("/app/pages/admin/apps/listing/index.vue")
  },
  {
    name: "admin-apps-mailbox",
    path: "/admin/apps/mailbox",
    component: () => import("/app/pages/admin/apps/mailbox.vue")
  },
  {
    name: "admin-apps-marketing-ads-detail",
    path: "/admin/apps/marketing/ads/detail",
    component: () => import("/app/pages/admin/apps/marketing/ads/detail.vue")
  },
  {
    name: "admin-apps-marketing-ads",
    path: "/admin/apps/marketing/ads",
    meta: indexgnoNqUUnsjMeta || {},
    component: () => import("/app/pages/admin/apps/marketing/ads/index.vue")
  },
  {
    name: "admin-apps-marketing-email-add",
    path: "/admin/apps/marketing/email/add",
    component: () => import("/app/pages/admin/apps/marketing/email/add.vue")
  },
  {
    name: "admin-apps-marketing-email",
    path: "/admin/apps/marketing/email",
    component: () => import("/app/pages/admin/apps/marketing/email/index.vue")
  },
  {
    name: "admin-apps-marketing-email-update",
    path: "/admin/apps/marketing/email/update",
    component: () => import("/app/pages/admin/apps/marketing/email/update.vue")
  },
  {
    name: "admin-apps-marketing-social_facebook-callback",
    path: "/admin/apps/marketing/social_facebook/callback",
    meta: callback4EY8sQQWbAMeta || {},
    component: () => import("/app/pages/admin/apps/marketing/social_facebook/callback.vue")
  },
  {
    name: "admin-apps-marketing-social_instagram-callback",
    path: "/admin/apps/marketing/social_instagram/callback",
    meta: callbacktRLWoyTaLvMeta || {},
    component: () => import("/app/pages/admin/apps/marketing/social_instagram/callback.vue")
  },
  {
    name: "admin-apps-notes",
    path: "/admin/apps/notes",
    meta: notesuJce14B4jdMeta || {},
    component: () => import("/app/pages/admin/apps/notes.vue")
  },
  {
    name: "admin-apps-opportunities",
    path: "/admin/apps/opportunities",
    meta: opportunitiesgzfaHtfqiOMeta || {},
    component: () => import("/app/pages/admin/apps/opportunities.vue")
  },
  {
    name: "admin-apps-scrumboard",
    path: "/admin/apps/scrumboard",
    component: () => import("/app/pages/admin/apps/scrumboard.vue")
  },
  {
    name: "admin-apps-smartaction-add",
    path: "/admin/apps/smartaction/add",
    component: () => import("/app/pages/admin/apps/smartaction/add.vue")
  },
  {
    name: "admin-apps-smartaction",
    path: "/admin/apps/smartaction",
    meta: indexPtrZjsiT1OMeta || {},
    component: () => import("/app/pages/admin/apps/smartaction/index.vue")
  },
  {
    name: "admin-apps-smartactionbu",
    path: "/admin/apps/smartactionbu",
    meta: smartactionbuGvn7i4CdMqMeta || {},
    component: () => import("/app/pages/admin/apps/smartactionbu.vue")
  },
  {
    name: "admin-apps-todolist-detail_todolist",
    path: "/admin/apps/todolist/detail_todolist",
    meta: detail_todolist2dW5wM4oZ2Meta || {},
    component: () => import("/app/pages/admin/apps/todolist/detail_todolist.vue")
  },
  {
    name: "admin-apps-todolist-index copy 2",
    path: "/admin/apps/todolist/index%20copy%202",
    meta: index_32copy_322myaTVrsfpEMeta || {},
    component: () => import("/app/pages/admin/apps/todolist/index copy 2.vue")
  },
  {
    name: "admin-apps-todolist-index copy",
    path: "/admin/apps/todolist/index%20copy",
    component: () => import("/app/pages/admin/apps/todolist/index copy.vue")
  },
  {
    name: "admin-apps-todolist",
    path: "/admin/apps/todolist",
    component: () => import("/app/pages/admin/apps/todolist/index.vue")
  },
  {
    name: "admin-apps-user-account-settings",
    path: "/admin/apps/user-account-settings",
    component: () => import("/app/pages/admin/apps/user-account-settings.vue")
  },
  {
    name: "admin-apps-user-detail",
    path: "/admin/apps/user/detail",
    component: () => import("/app/pages/admin/apps/user/detail.vue")
  },
  {
    name: "admin-apps-user",
    path: "/admin/apps/user",
    meta: indexbffr1T5plMMeta || {},
    component: () => import("/app/pages/admin/apps/user/index.vue")
  },
  {
    name: "admin-apps-website-add",
    path: "/admin/apps/website/add",
    meta: addRFyn3bjEXAMeta || {},
    component: () => import("/app/pages/admin/apps/website/add.vue")
  },
  {
    name: "admin-apps-website-detail",
    path: "/admin/apps/website/detail",
    component: () => import("/app/pages/admin/apps/website/detail.vue")
  },
  {
    name: "admin-apps-website-domain",
    path: "/admin/apps/website/domain",
    meta: domainU1EpGLef56Meta || {},
    component: () => import("/app/pages/admin/apps/website/domain.vue")
  },
  {
    name: "admin-apps-website",
    path: "/admin/apps/website",
    meta: indexQuQgh0JEixMeta || {},
    component: () => import("/app/pages/admin/apps/website/index.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexi8nCATZISJMeta || {},
    component: () => import("/app/pages/admin/index.vue")
  },
  {
    name: "admin-users-invoice",
    path: "/admin/users/invoice",
    component: () => import("/app/pages/admin/users/invoice.vue")
  },
  {
    name: "admin-users-profile",
    path: "/admin/users/profile",
    component: () => import("/app/pages/admin/users/profile.vue")
  },
  {
    name: "admin-users-profile2",
    path: "/admin/users/profile2",
    meta: profile2icUVa45uJFMeta || {},
    component: () => import("/app/pages/admin/users/profile2.vue")
  },
  {
    name: "admin-users-user-account-settings",
    path: "/admin/users/user-account-settings",
    component: () => import("/app/pages/admin/users/user-account-settings.vue")
  },
  {
    name: "analytics",
    path: "/analytics",
    component: () => import("/app/pages/analytics.vue")
  },
  {
    name: "auth-boxed-change-reset",
    path: "/auth/boxed-change-reset",
    meta: boxed_45change_45resetrxOrXTK4kZMeta || {},
    component: () => import("/app/pages/auth/boxed-change-reset.vue")
  },
  {
    name: "auth-boxed-lockscreen",
    path: "/auth/boxed-lockscreen",
    meta: boxed_45lockscreen9PuutlCpOVMeta || {},
    component: () => import("/app/pages/auth/boxed-lockscreen.vue")
  },
  {
    name: "auth-boxed-password-reset",
    path: "/auth/boxed-password-reset",
    meta: boxed_45password_45reset47WA1dDqs6Meta || {},
    component: () => import("/app/pages/auth/boxed-password-reset.vue")
  },
  {
    name: "auth-boxed-signin",
    path: "/auth/boxed-signin",
    meta: boxed_45signinPQdz4KevkGMeta || {},
    component: () => import("/app/pages/auth/boxed-signin.vue")
  },
  {
    name: "auth-boxed-signup",
    path: "/auth/boxed-signup",
    meta: boxed_45signup6O9TBPg4tGMeta || {},
    component: () => import("/app/pages/auth/boxed-signup.vue")
  },
  {
    name: "auth-cover-lockscreen",
    path: "/auth/cover-lockscreen",
    meta: cover_45lockscreenYk2MgkwpFmMeta || {},
    component: () => import("/app/pages/auth/cover-lockscreen.vue")
  },
  {
    name: "auth-cover-login",
    path: "/auth/cover-login",
    meta: cover_45loginuZyWJQcrBoMeta || {},
    component: () => import("/app/pages/auth/cover-login.vue")
  },
  {
    name: "auth-cover-password-reset",
    path: "/auth/cover-password-reset",
    meta: cover_45password_45reset0Dp209zw2BMeta || {},
    component: () => import("/app/pages/auth/cover-password-reset.vue")
  },
  {
    name: "auth-cover-register",
    path: "/auth/cover-register",
    meta: cover_45registervmnxlWtp1cMeta || {},
    component: () => import("/app/pages/auth/cover-register.vue")
  },
  {
    name: "auth-try-demo",
    path: "/auth/try-demo",
    meta: try_45demouZyExdwi4bMeta || {},
    component: () => import("/app/pages/auth/try-demo.vue")
  },
  {
    name: "b2c-detail",
    path: "/b2c/detail",
    meta: detailtjvjrgmvoKMeta || {},
    component: () => import("/app/pages/b2c/detail.vue")
  },
  {
    name: "b2c",
    path: "/b2c",
    meta: indexZylsoS0OD7Meta || {},
    component: () => import("/app/pages/b2c/index.vue")
  },
  {
    name: "b2c-marketing",
    path: "/b2c/marketing",
    meta: marketingnqQ7kgu8hvMeta || {},
    component: () => import("/app/pages/b2c/marketing.vue")
  },
  {
    name: "b2c-property",
    path: "/b2c/property",
    meta: propertyCcX57MkNdmMeta || {},
    component: () => import("/app/pages/b2c/property.vue")
  },
  {
    name: "charts",
    path: "/charts",
    component: () => import("/app/pages/charts.vue")
  },
  {
    name: "components-accordions",
    path: "/components/accordions",
    component: () => import("/app/pages/components/accordions.vue")
  },
  {
    name: "components-cards",
    path: "/components/cards",
    component: () => import("/app/pages/components/cards.vue")
  },
  {
    name: "components-carousel",
    path: "/components/carousel",
    component: () => import("/app/pages/components/carousel.vue")
  },
  {
    name: "components-countdown",
    path: "/components/countdown",
    component: () => import("/app/pages/components/countdown.vue")
  },
  {
    name: "components-counter",
    path: "/components/counter",
    component: () => import("/app/pages/components/counter.vue")
  },
  {
    name: "components-lightbox",
    path: "/components/lightbox",
    component: () => import("/app/pages/components/lightbox.vue")
  },
  {
    name: "components-list-group",
    path: "/components/list-group",
    component: () => import("/app/pages/components/list-group.vue")
  },
  {
    name: "components-media-object",
    path: "/components/media-object",
    component: () => import("/app/pages/components/media-object.vue")
  },
  {
    name: "components-modals",
    path: "/components/modals",
    component: () => import("/app/pages/components/modals.vue")
  },
  {
    name: "components-notifications",
    path: "/components/notifications",
    component: () => import("/app/pages/components/notifications.vue")
  },
  {
    name: "components-pricing-table",
    path: "/components/pricing-table",
    component: () => import("/app/pages/components/pricing-table.vue")
  },
  {
    name: "components-sweetalert",
    path: "/components/sweetalert",
    component: () => import("/app/pages/components/sweetalert.vue")
  },
  {
    name: "components-tabs",
    path: "/components/tabs",
    component: () => import("/app/pages/components/tabs.vue")
  },
  {
    name: "components-timeline",
    path: "/components/timeline",
    component: () => import("/app/pages/components/timeline.vue")
  },
  {
    name: "crypto",
    path: "/crypto",
    component: () => import("/app/pages/crypto.vue")
  },
  {
    name: "datatables-advanced",
    path: "/datatables/advanced",
    component: () => import("/app/pages/datatables/advanced.vue")
  },
  {
    name: "datatables-alt-pagination",
    path: "/datatables/alt-pagination",
    component: () => import("/app/pages/datatables/alt-pagination.vue")
  },
  {
    name: "datatables-basic",
    path: "/datatables/basic",
    component: () => import("/app/pages/datatables/basic.vue")
  },
  {
    name: "datatables-checkbox",
    path: "/datatables/checkbox",
    component: () => import("/app/pages/datatables/checkbox.vue")
  },
  {
    name: "datatables-clone-header",
    path: "/datatables/clone-header",
    component: () => import("/app/pages/datatables/clone-header.vue")
  },
  {
    name: "datatables-column-chooser",
    path: "/datatables/column-chooser",
    component: () => import("/app/pages/datatables/column-chooser.vue")
  },
  {
    name: "datatables-columns-filter",
    path: "/datatables/columns-filter",
    component: () => import("/app/pages/datatables/columns-filter.vue")
  },
  {
    name: "datatables-export",
    path: "/datatables/export",
    component: () => import("/app/pages/datatables/export.vue")
  },
  {
    name: "datatables-multi-column",
    path: "/datatables/multi-column",
    component: () => import("/app/pages/datatables/multi-column.vue")
  },
  {
    name: "datatables-multiple-tables",
    path: "/datatables/multiple-tables",
    component: () => import("/app/pages/datatables/multiple-tables.vue")
  },
  {
    name: "datatables-order-sorting",
    path: "/datatables/order-sorting",
    component: () => import("/app/pages/datatables/order-sorting.vue")
  },
  {
    name: "datatables-range-search",
    path: "/datatables/range-search",
    component: () => import("/app/pages/datatables/range-search.vue")
  },
  {
    name: "datatables-skin",
    path: "/datatables/skin",
    component: () => import("/app/pages/datatables/skin.vue")
  },
  {
    name: "datatables-sticky-header",
    path: "/datatables/sticky-header",
    component: () => import("/app/pages/datatables/sticky-header.vue")
  },
  {
    name: "dragndrop",
    path: "/dragndrop",
    component: () => import("/app/pages/dragndrop.vue")
  },
  {
    name: "editor copy",
    path: "/editor%20copy",
    component: () => import("/app/pages/editor copy.vue")
  },
  {
    name: "editor-test",
    path: "/editor-test",
    component: () => import("/app/pages/editor-test.vue")
  },
  {
    name: "editor",
    path: "/editor",
    component: () => import("/app/pages/editor.vue")
  },
  {
    name: "elements-alerts",
    path: "/elements/alerts",
    component: () => import("/app/pages/elements/alerts.vue")
  },
  {
    name: "elements-avatar",
    path: "/elements/avatar",
    component: () => import("/app/pages/elements/avatar.vue")
  },
  {
    name: "elements-badges",
    path: "/elements/badges",
    component: () => import("/app/pages/elements/badges.vue")
  },
  {
    name: "elements-breadcrumbs",
    path: "/elements/breadcrumbs",
    component: () => import("/app/pages/elements/breadcrumbs.vue")
  },
  {
    name: "elements-buttons-group",
    path: "/elements/buttons-group",
    component: () => import("/app/pages/elements/buttons-group.vue")
  },
  {
    name: "elements-buttons",
    path: "/elements/buttons",
    component: () => import("/app/pages/elements/buttons.vue")
  },
  {
    name: "elements-color-library",
    path: "/elements/color-library",
    component: () => import("/app/pages/elements/color-library.vue")
  },
  {
    name: "elements-dropdown",
    path: "/elements/dropdown",
    component: () => import("/app/pages/elements/dropdown.vue")
  },
  {
    name: "elements-infobox",
    path: "/elements/infobox",
    component: () => import("/app/pages/elements/infobox.vue")
  },
  {
    name: "elements-jumbotron",
    path: "/elements/jumbotron",
    component: () => import("/app/pages/elements/jumbotron.vue")
  },
  {
    name: "elements-loader",
    path: "/elements/loader",
    component: () => import("/app/pages/elements/loader.vue")
  },
  {
    name: "elements-pagination",
    path: "/elements/pagination",
    component: () => import("/app/pages/elements/pagination.vue")
  },
  {
    name: "elements-popovers",
    path: "/elements/popovers",
    component: () => import("/app/pages/elements/popovers.vue")
  },
  {
    name: "elements-progress-bar",
    path: "/elements/progress-bar",
    component: () => import("/app/pages/elements/progress-bar.vue")
  },
  {
    name: "elements-search",
    path: "/elements/search",
    component: () => import("/app/pages/elements/search.vue")
  },
  {
    name: "elements-tooltips",
    path: "/elements/tooltips",
    component: () => import("/app/pages/elements/tooltips.vue")
  },
  {
    name: "elements-treeview",
    path: "/elements/treeview",
    component: () => import("/app/pages/elements/treeview.vue")
  },
  {
    name: "elements-typography",
    path: "/elements/typography",
    component: () => import("/app/pages/elements/typography.vue")
  },
  {
    name: "finance",
    path: "/finance",
    component: () => import("/app/pages/finance.vue")
  },
  {
    name: "font-icons",
    path: "/font-icons",
    component: () => import("/app/pages/font-icons.vue")
  },
  {
    name: "forms-basic",
    path: "/forms/basic",
    component: () => import("/app/pages/forms/basic.vue")
  },
  {
    name: "forms-checkbox-radio",
    path: "/forms/checkbox-radio",
    component: () => import("/app/pages/forms/checkbox-radio.vue")
  },
  {
    name: "forms-clipboard",
    path: "/forms/clipboard",
    component: () => import("/app/pages/forms/clipboard.vue")
  },
  {
    name: "forms-date-picker",
    path: "/forms/date-picker",
    component: () => import("/app/pages/forms/date-picker.vue")
  },
  {
    name: "forms-file-upload",
    path: "/forms/file-upload",
    component: () => import("/app/pages/forms/file-upload.vue")
  },
  {
    name: "forms-input-group",
    path: "/forms/input-group",
    component: () => import("/app/pages/forms/input-group.vue")
  },
  {
    name: "forms-input-mask",
    path: "/forms/input-mask",
    component: () => import("/app/pages/forms/input-mask.vue")
  },
  {
    name: "forms-layouts",
    path: "/forms/layouts",
    component: () => import("/app/pages/forms/layouts.vue")
  },
  {
    name: "forms-markdown-editor",
    path: "/forms/markdown-editor",
    component: () => import("/app/pages/forms/markdown-editor.vue")
  },
  {
    name: "forms-quill-editor",
    path: "/forms/quill-editor",
    component: () => import("/app/pages/forms/quill-editor.vue")
  },
  {
    name: "forms-select2",
    path: "/forms/select2",
    component: () => import("/app/pages/forms/select2.vue")
  },
  {
    name: "forms-switches",
    path: "/forms/switches",
    component: () => import("/app/pages/forms/switches.vue")
  },
  {
    name: "forms-touchspin",
    path: "/forms/touchspin",
    component: () => import("/app/pages/forms/touchspin.vue")
  },
  {
    name: "forms-validation",
    path: "/forms/validation",
    component: () => import("/app/pages/forms/validation.vue")
  },
  {
    name: "forms-wizards",
    path: "/forms/wizards",
    component: () => import("/app/pages/forms/wizards.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "invoice",
    path: "/invoice",
    meta: invoiceFIX0IQeAuyMeta || {},
    component: () => import("/app/pages/invoice.vue")
  },
  {
    name: "order",
    path: "/order",
    meta: orderBjfxujOe1OMeta || {},
    component: () => import("/app/pages/order.vue")
  },
  {
    name: "pages-coming-soon-boxed",
    path: "/pages/coming-soon-boxed",
    meta: coming_45soon_45boxedIiRVY5RmnYMeta || {},
    component: () => import("/app/pages/pages/coming-soon-boxed.vue")
  },
  {
    name: "pages-coming-soon-cover",
    path: "/pages/coming-soon-cover",
    meta: coming_45soon_45cover9En00x5dcNMeta || {},
    component: () => import("/app/pages/pages/coming-soon-cover.vue")
  },
  {
    name: "pages-contact-us-boxed",
    path: "/pages/contact-us-boxed",
    meta: contact_45us_45boxedCNp7hQWXH5Meta || {},
    component: () => import("/app/pages/pages/contact-us-boxed.vue")
  },
  {
    name: "pages-contact-us-cover",
    path: "/pages/contact-us-cover",
    meta: contact_45us_45coverDqlH83GANbMeta || {},
    component: () => import("/app/pages/pages/contact-us-cover.vue")
  },
  {
    name: "pages-error404",
    path: "/pages/error404",
    meta: error404hTN0doY6zqMeta || {},
    component: () => import("/app/pages/pages/error404.vue")
  },
  {
    name: "pages-error500",
    path: "/pages/error500",
    meta: error500OQ7pwxlt92Meta || {},
    component: () => import("/app/pages/pages/error500.vue")
  },
  {
    name: "pages-error503",
    path: "/pages/error503",
    meta: error503mOESQwwiUHMeta || {},
    component: () => import("/app/pages/pages/error503.vue")
  },
  {
    name: "pages-faq",
    path: "/pages/faq",
    component: () => import("/app/pages/pages/faq.vue")
  },
  {
    name: "pages-knowledge-base",
    path: "/pages/knowledge-base",
    component: () => import("/app/pages/pages/knowledge-base.vue")
  },
  {
    name: "pages-maintenence",
    path: "/pages/maintenence",
    meta: maintenenceZfksFlNFGfMeta || {},
    component: () => import("/app/pages/pages/maintenence.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: profileupBlgdy3vLMeta || {},
    component: () => import("/app/pages/profile.vue")
  },
  {
    name: "success",
    path: "/success",
    meta: success0bZk2aWKHZMeta || {},
    component: () => import("/app/pages/success.vue")
  },
  {
    name: "tables",
    path: "/tables",
    component: () => import("/app/pages/tables.vue")
  },
  {
    name: "trial-mode",
    path: "/trial-mode",
    meta: trial_45modevh6rBg3TbfMeta || {},
    component: () => import("/app/pages/trial-mode.vue")
  },
  {
    name: "widgets",
    path: "/widgets",
    component: () => import("/app/pages/widgets.vue")
  }
]