<template>
    <div>
        <!-- Loading Spinner -->
        <div v-if="isLoading" class="flex items-center justify-center">
            <LoadingSpinner />
        </div>

        <!-- Main Content -->
        <div v-else>
            <div class="pb-5">
                <div class="mb-4 flex items-center justify-between">
                    <!-- Back Button -->
                    <button @click="goBack" class="hover:bg-primary-dark flex items-center rounded-md bg-primary px-4 py-2 text-white transition-colors">
                        <!-- SVG Arrow Icon -->
                        <svg class="mr-2 h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path>
                        </svg>
                        Back
                    </button>

                    <!-- Buttons Group -->

                </div>
            </div>
            <div class="container mx-auto rounded-lg bg-white p-6 shadow-md">
                <div class="mb-4 flex items-center justify-between">
                    <h2 class="text-2xl font-semibold">{{ title }}</h2>
                    <div class="grid grid-cols-1 gap-2">
                        <div class="mb-5">
                            <ol class="flex font-semibold text-primary dark:text-white-dark">
                                <li
                                    v-for="(stage, index) in projectList"
                                    :key="stage.id"
                                    :class="{
                                        'bg-[#ebedf2]': true,
                                        'dark:bg-[#1b2e4b]': true,
                                        'ltr:rounded-l-md': index === 0,
                                        'rtl:rounded-r-md': index === projectList.length - 1,
                                        'bg-primary text-white': stage.id === activeProjectId,
                                    }"
                                >
                                    <a
                                        href="javascript:;"
                                        :class="{
                                            'relative mx-5 flex h-full items-center p-1.5 before:absolute before:inset-y-0 before:z-[1] before:m-auto before:h-0 before:w-0 before:border-r-0 before:border-b-transparent before:border-l-[#ebedf2] before:border-t-transparent hover:text-primary/70 dark:before:border-l-[#1b2e4b] dark:hover:text-white-dark/70 ltr:pl-3 ltr:pr-2 ltr:before:-right-[15px] rtl:pl-2 rtl:pr-3 rtl:before:-left-[15px] rtl:before:rotate-180':
                                                'relative flex h-full items-center p-1.5 px-3 before:absolute before:inset-y-0 before:z-[1] before:m-auto before:h-0 before:w-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-b-transparent before:border-l-[#ebedf2] before:border-t-transparent hover:text-primary/70 dark:before:border-l-[#1b2e4b] dark:hover:text-white-dark/70 ltr:pl-6 ltr:before:-right-[15px] rtl:pr-6 rtl:before:-left-[15px] rtl:before:rotate-180',
                                        }"
                                    >
                                        {{ stage.name }}
                                    </a>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>


                <div class="mb-4">
                    <!-- <div>
                        <span class="block text-sm">Customer</span>
                        <span class="text-lg">{{ title }}</span>
                    </div> -->
                    <div>
                        <span class="block text-sm">Description</span>
                        <span class="text-lg">{{ description }}</span>
                    </div>
                    <div>
                        <span class="block text-sm">Date Assign </span>
                        <span class="text-lg">{{ date_assign.split(' ')[0] }}</span>
                    </div>
                    <div>
                        <span class="block text-sm">Date Deadline</span>
                        <span class="text-lg">{{ date_deadline.split(' ')[0] }}</span>
                    </div>
                    <div>
                        <span class="block text-sm">Date End</span>
                        <span class="text-lg">{{ date_end.split(' ')[0] }}</span>
                    </div>

                </div>
                <!-- <div class="mb-4">
                    <div class="flex items-center justify-between">
                        <span class="text-lg">Expected Closing</span>
                        <span class="text-lg font-semibold">{{ formattedDate }}</span>
                    </div>
                    <div class="flex items-center justify-between">
                        <span class="text-lg">Tags</span>
                        <span class="text-lg font-semibold">{{ tags }}</span>
                    </div>
                </div> -->

                <!-- List of Activities -->

            </div>
        </div>
        <transition name="fade">
        <div v-if="isLossModalOpen" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div class="bg-white rounded-lg shadow-lg w-1/3">
            <div class="p-4 border-b">
                <h3 class="text-xl font-semibold">Mark Lost</h3>
            </div>
            <div class="p-4">
                <!-- Lost Reason Input -->
                <div class="mb-4">
                <label class="block text-sm font-medium text-gray-700">Lost Reason</label>
                <input
                    v-model="lostReason"
                    type="text"
                    class="mt-1form-input  block w-full border border-gray-300 rounded-md shadow-sm"
                    placeholder="Enter reason"
                />
                </div>
            </div>
            <div class="p-4 border-t flex justify-end">
                <button @click="markAsLost" class="btn btn-primary mr-2">Mark as Lost</button>
                <button @click="closeLossModal" class="btn btn-secondary">Cancel</button>
            </div>
            </div>
        </div>
        </transition>
    </div>
</template>

<script lang="ts">
    import { defineComponent, ref, onMounted, computed } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { getTaskStageList, listCalendarActivities, updateCrmLead, updateCrmLeadLost } from '~/utils/apiService';
    import { useAppStore } from '@/stores/index';
    import { useAuthStore } from '@/stores/authStore';
    import { useLeadsStore } from '@/stores/leadsStore';
    import LoadingSpinner from '~/components/LoadingSpinner.vue';

    export default defineComponent({
        name: 'OpportunityPage',
        components: {
            LoadingSpinner,
        },
        setup() {
            const store = useAppStore();
            const leadsStore = useLeadsStore();
            const authStore = useAuthStore();
            const route = useRoute();
            const router = useRouter();

            const title = ref('');
            const id = ref('');
            const description = ref('');
            const date_assign = ref('');
            const date_deadline = ref('');
            const date_end = ref('');
            const probability = ref<number | null>(null);
            const revenue = ref<number | null>(null);
            const formattedDate = ref('');
            const tags = ref('');
            const projectList = ref([]);
            const activeProjectId = ref<number | null>(null);
            const activities = ref<any[]>([]);
            const isLoading = ref(true); // Loading state
            // Modal state
            const isLossModalOpen = ref(false);
            const selectedLostReason = ref('');
            const lostReason = ref('');

            const closingNote = ref('');
            definePageMeta({
                middleware: 'auth',
            });
            const openLossModal = () => {
                isLossModalOpen.value = true;
            };

            const closeLossModal = () => {
                isLossModalOpen.value = false;
                lostReason.value = '';
                closingNote.value = '';
            };

            const markAsLost = async () => {
                try {
                    if (lostReason.value == '') {
                        showMessage('Please select a reason for the loss.', 'danger');
                        return;
                    }

                    const dataCrm = {
                        company_id: parseInt(authStore.companyId),
                        lead_id: parseInt(id.value),
                        partner_id: parseInt(authStore.partnerId),
                        lost_reason: lostReason.value,
                        offset: 0,
                        limit: 0,
                    };
                    await updateCrmLeadLost(dataCrm);
                    showMessage('Opportunity marked as lost.', 'success');
                    closeLossModal();
                    router.back();
                } catch (error) {
                    console.error('Failed to mark opportunity as lost', error);
                }
            };

            const formatRevenue = (value: number | null) => {
                return value ? `Rp${value.toLocaleString()}.00` : 'N/A';
            };

            const formatDate = (dateStr: string) => {
                const date = new Date(dateStr);
                return date.toLocaleDateString('id-ID') + ' ' + date.toLocaleTimeString('id-ID');
            };

            const wonButton = async () => {
                try {
                    const dataCrm = {
                        company_id: parseInt(authStore.companyId),
                        lead_id: parseInt(id.value),
                        partner_id: parseInt(authStore.partnerId),
                        edit: {
                            stage_id: 4,
                        },
                    };
                    await updateCrmLead(dataCrm);
                    // fetchCRMStageList()
                    showMessage('Task project assignment has been updated.', 'success');
                } catch (error) {
                    console.error('Failed to update task project assignment', error);
                }
            };
            const lossButton = () => {};
            const goBack = () => {
                router.back();
            };

            const fetchCRMStageList = async () => {
                try {
                    const params = {
                        name: '',
                        limit: 0,
                        offset: 0,
                        stage_id: 0,
                    };
                    const response = await getTaskStageList(params);
                    projectList.value = response.data;
                } catch (error) {
                    console.error('Failed to fetch CRM stages', error);
                }
            };

            const fetchCalendarActivities = async () => {
                try {
                    const params = {
                        company_id: parseInt(authStore.companyId),
                        description: '',
                        lead_id: parseInt(id.value),
                        limit: 0,
                        name: '',
                        offset: 0,
                        partner_id: parseInt(authStore.partnerId),
                        start_date: '',
                        stop_date: '',
                    };
                    const response = await listCalendarActivities(params);
                    activities.value = response.data;
                } catch (error) {
                    console.error('Failed to fetch calendar activities', error);
                }
            };

            onMounted(async () => {
                try {
                    await fetchCRMStageList();
                    await fetchCalendarActivities();
                } finally {
                    isLoading.value = false; // Set loading to false after fetching
                }

                const {
                    title: qTitle,
                    description: qDescription,
                    phone: qPhone,
                    probability: qProbability,
                    revenue: qRevenue,
                    date: qDate,
                    tags: qTags,
                    date_assign:qdate_assign,
                    date_deadline:qdate_deadline,
                    date_end:qdate_end,
                    id: qID,
                    projectId,
                } = route.query;

                title.value = (qTitle as string) || '';
                id.value = (qID as string) || '';
                description.value = (qDescription as string) || '';
                date_assign.value = (qdate_assign as string) || '';
                date_deadline.value = (qdate_deadline as string) || '';
                date_end.value = (qdate_end as string) || '';
                probability.value = qProbability ? Number(qProbability) : null;
                revenue.value = qRevenue ? Number(qRevenue) : null;
                formattedDate.value = (qDate as string) || '';
                tags.value = (qTags as string) || '';
                activeProjectId.value = projectId ? Number(projectId) : null;
            });

            // Construct URL with current query parameters
            const activityUrl = computed(() => {
                const queryParams = new URLSearchParams(route.query as any).toString();
                return `/admin/apps/crm/activity?${queryParams}`;
            });

            return {
                id,
                title,
                description,
                date_deadline,
                date_end,
                date_assign,
                probability,
                formattedRevenue: computed(() => formatRevenue(revenue.value)),
                formattedDate,
                tags,
                projectList,
                goBack,
                activeProjectId,
                activityUrl,
                activities,
                formatDate,
                isLoading,
                wonButton,
                openLossModal,
                closeLossModal,
                markAsLost,
                isLossModalOpen,
                lostReason,
                closingNote,
            };
        },
    });
</script>

<style scoped></style>
