import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import auth_vT9JWWT9pN from "/app/plugins/auth.ts";
import maska_client_D8wFsCvpJf from "/app/plugins/maska.client.ts";
import perfect_scrollbar_eDszbQJYAy from "/app/plugins/perfect-scrollbar.ts";
import tippy_client_WKoqvTKVZ4 from "/app/plugins/tippy.client.ts";
import vue_easymde_client_a1tIVvYavg from "/app/plugins/vue-easymde.client.ts";
import vue3_apexcharts_client_rlebatt9PH from "/app/plugins/vue3-apexcharts.client.ts";
import vue3_json_excel_client_kZvvvCfyHK from "/app/plugins/vue3-json-excel.client.ts";
import vue3_popper_client_4mGDrhCm4m from "/app/plugins/vue3-popper.client.ts";
import vue3_quill_client_iXMSHkxwf2 from "/app/plugins/vue3-quill.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  auth_vT9JWWT9pN,
  maska_client_D8wFsCvpJf,
  perfect_scrollbar_eDszbQJYAy,
  tippy_client_WKoqvTKVZ4,
  vue_easymde_client_a1tIVvYavg,
  vue3_apexcharts_client_rlebatt9PH,
  vue3_json_excel_client_kZvvvCfyHK,
  vue3_popper_client_4mGDrhCm4m,
  vue3_quill_client_iXMSHkxwf2
]